import React from 'react'
// import Video from '../../../static/img/ntv-video-gold-zinsen.MP4'
import Layout from '../../components/Layout'
import BlogRoll from '../../components/BlogRoll'
import PreviewCompatibleImage from '../../components/PreviewCompatibleImage'

export default class BlogIndexPage extends React.Component {
  render() {
    return (
      <Layout>
        <div
          className="full-width-image-container margin-top-0"
          style={{
            backgroundImage: `url('/img/agau-13-jena-2.jpg')`,
          }}
        >
          <h1
            className="is-size-1 products-banner"
          >
            Wissenwertes
          </h1>
        </div>
        <section className="section">
          <div className="container">
            <div className="content blog-roll">
              <h2>Wie können wir unser Geld retten?</h2>
              <h3>Hier poste ich interessante Artikel und Videos, damit Sie mehr über das Zeitalter der Sachwerte efahren können:</h3>
              <BlogRoll />
          <div className="take-action columns">
            <div className="contact-img column-is-6">
              <PreviewCompatibleImage
                imageInfo={{
                  image: '/img/christian-ferber.png',
                  alt: `Christian Ferber`,
                }}
              />

            </div>
            <div className="contact-infos column-is-6">
              <h4>Vereinbaren Sie heute einen Termin!</h4>
              <h3>Christian Ferber</h3>
              <p>Fachwirt für Finanzen, Versicherungen und Investmentfonds</p>
                <p>03641-3096124</p>
                <a href="mailto:Info@agau-edelmetalle.de">info@agau-edelmetalle.de</a>

            </div>
          </div>
          <article className="message">
            <div className="message-body">
            Keine Papierwährung kann mit Gold mithalten
              <br />
              <cite> - Alan Greenspan</cite>
            </div>
          </article>
          <article className="message">
            <div className="message-body">
            Gold ist Geld und nichts anderes
              <br />
              <cite> - JP Morgan</cite>
            </div>
          </article>
          <article className="message">
            <div className="message-body">
            Geld kehrt früher oder später zu seinem inneren Wert zurück: Null
              <br />
              <cite> - Voltaire</cite>
            </div>
          </article>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}
